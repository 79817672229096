import request from "../utils/request"
const api = '/api'
// 登录
export  const login = async (params)=>{
    return request.get(api+'/login',{params})
}
// 激活
export  const activeAccount = async (data)=>{
    return request.post(api+'/activeAccount',data)
}
// 发送忘记密码邮件
export  const sendForget = async (data)=>{
    return request.post(api+'/sendForgetPasswdEmail',data)
}
// 忘记密码
export  const forgetPasswdByEmail = async (data)=>{
    return request.post(api+'/forgetPasswdByEmail',data)
}
// 返回剩余的毫秒数
export  const sendEmailWait = async ()=>{
    return request.get(api+'/sendEmailWait',{})
}

// 修改密码
export  const changeUserPasswd = async (data)=>{
    return request.post(api+'/ai/changeUserPasswd',data)
}

// 发送账号激活邮件
export  const sendActiveEmail = async (data)=>{
    return request.post(api+'/sendActiveEmail',data)
}

// 激活账号（邮件）
export  const activeAccountByEmail = async (data)=>{
    return request.post(api+'/activeAccountByEmail',data)
}

// 账户充值
export  const userRecharge = async (data)=>{
    return request.post(api+'/ai/userRecharge',data)
}


