import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        titleName:""
    },
    getters: {},
    mutations: {
        setTitle(state,str){
            state.titleName = str;
        }
    },
    actions: {},
    modules: {},
});
