import request from "../utils/request";
const api = '/api'
// 用户对话列表
export  const getUserBatchList = async (params)=>{
    return request.get(api+'/ai/getUserBatchList',{params})
}

// 批次详情
export  const getUserBatchDetail = async (params)=>{
    return request.get(api+'/ai/getUserBatchDetail',{params})
}

// 用户列表
export  const getUserList = async (params)=>{
    return request.post(api+'/ai/getUserList',params)
}
// 启用或关闭用户
export  const changeUserState = async (params)=>{
    return request.post(api+'/ai/changeUserState',params)
}
// 批次生成激活码
export  const createUserBatch = async (params)=>{
    return request.post(api+'/ai/createUserBatch',params)
}



