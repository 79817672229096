<template>
    <div >
        
    </div>
</template>
<script>

export default {
    data() {
        return {
            userAgent: '',
            time:null
        }
    },
    created() {
        this.userAgent = navigator.userAgent;
        this.gerLoginEvent()
    },
    computed: {
        isMobile() {
            return ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'].some(item => this.userAgent.includes(item))
        }
    },
    methods: {
        gerLoginEvent(){
            clearInterval(this.time);
            this.time = setInterval(() =>{
                console.log('init')
                this.init()
            },1000)
        },
        init(){
            this.$router.replace('/login')
        },
        setRemUnit() {
            var docEl = document.documentElement;
            var rem = docEl.clientWidth / 10;
            docEl.style.fontSize = rem + 'px';
        }
    },
    beforeDestroy(){
        clearInterval(this.time);
    }

}
</script>
