import request from "../utils/request";
const api = '/api'
// 用户对话列表
export  const getUserTopicList = async ()=>{
    return request.get(api+'/ai/getUserTopicList',{})
}
// 对话历史
export  const getTopicHistory = async (params)=>{
    return request.get(api+'/ai/getTopicHistory',{params})
}
// 创建对话
export  const createTopic = async (data)=>{
    return request.post(api+'/ai/createTopic',data)
}
// 对话
export  const newChat = async (data)=>{
    return request.post(api+'/ai/newChat',data)
}

// 删除对话
export  const deleteTopic = async (data)=>{
    return request.post(api+'/ai/deleteTopic',data)
}

// 编辑对话名称
export  const editTopic = async (data)=>{
    return request.post(api+'/ai/editTopic',data)
}
// 用户使用情况
export  const getUserUsage = async ()=>{
    return request.get(api+'/ai/getUserUsage',{})
}

// 删除对话中的某一项记录
export  const deleteTopicLogInfo = async (data)=>{
    return request.post(api+'/ai/deleteTopicLogInfo',data)
}
// 修改历史对话内容
export  const editTopicLogInfo = async (data)=>{
    return request.post(api+'/ai/editTopicLogInfo',data)
}