import axios from "axios";
import {Message} from 'element-ui';
import router from "../router";
const instance = axios.create({
    baseURL: "",
    timeout: 1000000
});
// 请求拦截器
instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = "Bearer " + localStorage.getItem('mtToken') || ""
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 响应拦截器
instance.interceptors.response.use(
    (response) => {
        if(response.data.resultcode === '1002'){
            localStorage.removeItem('mtToken');
            Message({ type:"error", message:"您未登录，或者登录已经超时，请先登录！"});
            setTimeout(()=>{
                router.push('/direct')
            },1000)
            return Promise.reject(response.data);
        }else{
            return response.data;
        }
    },
    (error) => {
        try {
            let code = error.response.status;
            let message = "";
            switch (code) {
                case 302:
                    message = "接口重定向了！";
                    break;
                case 400:
                    message = "参数不正确！";
                    break;
                case 401:
                    message = "您未登录，或者登录已经超时，请先登录！";
                    localStorage.removeItem('mtToken');
                    break;
                case 403:
                    message = "您没有权限操作！";
                    break;
                case 404:
                    message = `请求地址出错: ${error.response.config.url}`;
                    break;
                case 408:
                    message = "请求超时！";
                    break;
                case 409:
                    message = "系统已存在相同数据！";
                    break;
                case 500:
                    message = "服务器内部错误！";
                    break;
                case 501:
                    message = "服务未实现！";
                    break;
                case 502:
                    message = "网关错误！";
                    break;
                case 503:
                    message = "服务不可用！";
                    break;
                case 504:
                    message = "服务暂时无法访问，请稍后再试！";
                    break;
                case 505:
                    message = "HTTP 版本不受支持！";
                    break;
                default:
                    message = "异常问题，请联系管理员！";
                    break;
            }
            Message({
                type:"error",
                message
            })
        } catch (err) {
            console.log(err, "catch");
        }

        return Promise.reject(error);
    }
);

export default instance;
