<template>
    <div class="msg-container f" :class="item.role == 'user' ? 'rig' : 'lef'">
        <div class="use-a">
            <div class="f flex_ac flex_jc w42 h42 use-pic">
                <span>{{ item.role == "user" ? "我" : "器" }}</span>
            </div>
        </div>
        <div class="use-content">
            <div class="block use-tip">
                <span clsas="inblock xu bgac w10 h10"></span>
                <span class="marL5 ac">{{ item.crDate }}</span>
                <span v-if="item.role == 'user'" class="marL15 dede">我</span>
            </div>
            <div class="f marT5 use-cont posr">
                <template v-if="item.role == 'user'">
                    <v-md-editor :autofocus="true" ref="mdEdit" :left-toolbar="leftBar" :right-toolbar="rightBar" v-if="msgItem" :mode="mode" v-model="item.content" @save="saveMsg"></v-md-editor>
                </template>
                <template v-else>
                    <load-animate v-show="!msgItem && mode=='preview'" />
                    <v-md-editor :autofocus="true" ref="mdEdit" :left-toolbar="leftBar" :right-toolbar="rightBar" v-if="msgItem || mode == 'edit'" :mode="mode" v-model="item.content" @save="saveMsg"></v-md-editor>
                </template>
                <div v-if="item.reqLogId" class="msg-right in-f msg-oper flex_jc">
                    <el-popover popper-class="msg-lp" v-model="popVisible" class="msg-sp f flex_ac" placement="bottom-start"
                        :visible-arrow="false" trigger="click" @show="poperShow" @hide="hidePopShow">
                        <ul class="menu-conta-box">
                            <li class="cur conta-item" @click="copyTextToClipboard(item.content)">
                                <img class="h21 img-de" src="../../../assets/copy.png" alt="复制">
                                <img class="h21 lig-img" src="../../../assets/copybg.png" alt="复制">
                                <span class="marL12 exp">复制</span>
                            </li>
                            <li class="cur conta-item" @click.stop="toEdit">
                                <img class="h21 img-de" src="../../../assets/edit.png" alt="编辑">
                                <img class="h21 lig-img" src="../../../assets/editbg.png" alt="编辑">
                                <span class="marL12 exp">编辑</span>
                            </li>
                            <li class="cur conta-item" @click="deleteItem">
                                <img class="h21 img-de" src="../../../assets/delete.png" alt="删除">
                                <img class="h21 lig-img" src="../../../assets/deletebg.png" alt="删除">
                                <span class="marL12 exp">删除</span>
                            </li>
                            <li class="cur conta-item img-quote" @click="toQuote">
                                <img class="h21 img-de" src="../../../assets/cite.png" alt="引用">
                                <img class="h21 lig-img" src="../../../assets/citebg.png" alt="引用">
                                <span class="marL12 exp">引用</span>
                            </li>
                        </ul>
                        <div class="w48 h48 f flex_ac flex_jc cur marL10 marR10" :class="{ 'ligheight': ligheight }"
                            slot="reference">
                            <span class="el-icon-more font24 mtdc"></span>
                        </div>
                    </el-popover>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import loadAnimate from "@/views/components/loadAnimate.vue";
import { editTopicLogInfo } from "@/api";
export default {
    name: 'editorItem',
    model: {
        prop: 'item',
        event: 'change'
    },
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    components: {
        loadAnimate
    },
    computed: {
        msgItem: {
            get() {
                return this.item.content || "";
            },
            set(val) {

            }
        }
    },
    data() {
        return {
            mode: "preview",
            menuFlag: false,
            ligheight: false,
            popVisible: false,
            leftBar:"save",
            rightBar:""
        }
    },
    created() {
        this.editName = this.item.topicName;
        document.addEventListener("keydown", function (e) {
            if (e.code === "KeyS" && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                e.preventDefault();
                // 在此处添加你的代码
            }
        }, false);
    },
    methods: {
        mdBlur(){
            console.log('触发了')
        },
        deleteItem(){
            this.$emit('deleteMsgItem',this.item.reqLogId);
            this.popVisible = false;
        },
        saveMsg() {
            this.mode = "preview";
            this.saveChangeText()
        },
        saveChangeText() {
            let params = {
                content: this.item.content,
                reqLogId: this.item.reqLogId
            }
            editTopicLogInfo(params).then((res) => {
                if (res.resultcode == 200) {
                    this.$message.success(res.reason || "保存成功");
                } else {
                    this.$message.error(res.reason || "网络错误");
                }
            })
        },
        toEdit() {
            this.hidePopShow();
            this.$emit('startEdit',this.item.reqLogId,this.item.content)
        },
        showConta() {
            this.menuFlag = true;
        },
        toQuote() {
            let str = this.formattingText(this.item.content);
            this.$emit('quote', str);
            this.hidePopShow()
        },
        formattingText() {
            let str = this.item.content;
            str = `>${this.item.content}\n-------------------\n`
            return str
        },
        copyTextToClipboard(text) {
            const input = document.createElement('textarea');
            input.value = text;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            this.$message.success('复制成功');
            this.hidePopShow()
        },
        poperShow() {
            this.ligheight = true;
        },
        showPopShow() {
            this.popVisible = true;
        },
        hidePopShow() {
            this.popVisible = false;
            this.ligheight = false;
        }
    },
};
</script>
<style lang="scss">
.menu-cont {
    display: flex;
    align-items: center;
    overflow: hidden;

    .menu-origin {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: #338cf2;
    }
}


.rig {
    display: flex;
    flex-direction: row-reverse;
    max-width: 75%;
    margin-left: auto;
    .use-a {
        margin-left: 10px;
    }

    .use-cont {
        flex-direction: row-reverse;
    }

    .use-tip {
        text-align: right;
    }
}

.lef {
    max-width: 75%;
    .use-a {
        margin-right: 10px;
    }

    .use-pic {
        background-color: #2bb2aa;
    }
}

.v-md-editor__toolbar-item {
    color: #dedede;
}

.v-md-textarea-editor textarea {
    color: #dedede;
    background-color: transparent;
}

.v-md-editor__toolbar-item:hover {
    background-color: #43494e;
    color: #dedede;
}

.v-md-editor__toolbar-item--active {
    background-color: transparent;
}

.v-md-editor__right-area {
    width: auto;
}

.menu-conta-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #43494E;
    color: #dedede;
    padding: 10px 0;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    .conta-item {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        .lig-img{
            display: none;
        }
    }

    .conta-item:hover {
        background-color: #33393e;
        .exp{
            color: #37a2ff;
        }
        .img-de{
            display: none;
        }
        .lig-img{
            display: inline-block;
        }
    }

    .exp {
        white-space: nowrap;
    }
}

.msg-lp {
    background-color: transparent;
    padding: 0;
    border: 0;
    margin: 0;
    min-width: none;
}

.ligheight {
    background-color: #33393e;
    border-radius: 50%;
}
</style>