import Vue from "vue";
import VueRouter from "vue-router";
import direct from "../views/direct";
import chitChat from "../views/chitchat";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "direct",
        component: direct,
        meta:{
            islogin:false
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login"),
        meta:{
            islogin:false
        },
    },
    {
        path: "/home",
        name: "home",
        component: () => import("../views/home/home.vue"),
        redirect:"chitchat",
        meta:{
            islogin:true
        },
        children:[
            {
                path: "/chitchat",
                name: "chitchat",
                component: () => import("../views/chitchat"),
                meta:{
                    islogin:true
                },
            },
            {
                path: "/changePassword",
                name: "changePassword",
                component: () => import("../views/changePassword"),
                meta:{
                    islogin:true
                },
            },
            {
                path: "/batch",
                name: "batch",
                component: () => import("../views/batch"),
                meta:{
                    islogin:true
                },
            },
            {
                path: "/bachDetails",
                name: "bachDetails",
                component: () => import("../views/bachDetails"),
                meta:{
                    islogin:true
                },
            },
            {
                path: "/management",
                name: "management",
                component: () => import("../views/management"),
                meta:{
                    islogin:true
                },
            }
        ],
        
    },
    {
        path: "/direct",
        name: "direct",
        meta:{
            islogin:false
        },
        component: () => import("../views/direct"),
    },
    {
        path: "/test",
        name: "test",
        meta:{
            islogin:false
        },
        component: () => import("../views/test"),
    },
    // 手机端页面
    {
        path: "/loginphone",
        name: "loginphone",
        meta:{
            islogin:false
        },
        component: () => import("../views/loginphone/loginphone.vue"),
    },
    {
        path: "/phHome",
        name: "phHome",
        meta:{
            islogin:true
        },
        component: () => import("../views/phHome/phHome.vue"),
    },
    {
        path: "/forgetPasswd",
        name: "forgetPasswd",
        meta:{
            islogin:false
        },
        component: () => import("../views/forgetPasswd"),
    },
    {
        path: "/activeAccount",
        name: "activeAccount",
        meta:{
            islogin:false
        },
        component: () => import("../views/activeAccount"),
    },
    
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    let flag = to.meta.islogin;
    const tokenStr = localStorage.getItem("mtToken");
    if (flag) {
        if(!tokenStr){
            next("/direct");
        }else{
            next();
        }
    } else {
        next();
    }
});
export default router;
