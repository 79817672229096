import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import iview from 'view-design/dist/iview.js';
import 'view-design/dist/styles/iview.css';
import 'element-ui/lib/theme-chalk/index.css';
import './style/base.css';
import './style/formbase.css';
import './style/index.css';
import './style/phone.css';
import './style/phoneBase.css';
import './utils/vant.js'
import './style/vuepressMarkdown.scss'
// import './style/element.scss'
Vue.use(ElementUI);
Vue.use(iview);
Vue.config.productionTip = false;
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';
// import hljs from 'highlight.js';
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
});

Vue.use(VueMarkdownEditor);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
