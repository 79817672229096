<template>
    <div class="w h ovh f flex_c">
        <div class="mt-content w  h  f flex_c ovh">
            <div class="content-list w h padT30 padL40 padR40 padB30 ovy" id="contentList" ref="scrllBox">
                <div v-for="(item, index) in msgList" :key="index" class="content-item marT10">
                    <editorItem :item.sync="msgList[index]" @quote="getQuote" @deleteMsgItem="deleMsgItem"
                        @startEdit="getEditValue" />
                </div>
            </div>
        </div>
        <div class="h2 w" style="background-color: #212429"></div>
        <div class="use-oper dede padT32 padL70 padR70 padB35">
            <div class="use-send-box f">
                <div class="use-import br8 flex1 ovh padT10 padB10" :class="[{ blig: light }]">
                    <el-input class="mt-text-send" ref="mtTextSenTear" id="mtTextSenTear" resize="none"
                        @keydown.enter.native="carriageReturn($event)" type="textarea" @focus="setLight(true)"
                        @blur="setLight(false)" :autosize="{ minRows: 1, maxRows: 18 }" placeholder="请输入内容"
                        :disabled="loadFlag" v-model="sendMsg" clearable88></el-input>
                    <div class="ttt" :class="{ bgc: loadFlag }"></div>
                </div>
                <div class="use-send f flex_ac">
                    <template v-if="startEditButtonStatus">
                        <div @click="toSaveMsg" class="send-ico send-edit w40 h40 f flex_ac flex_jc cur nouse">
                            <img class="w20 h20" src="../../assets/saceEdit.png" alt="">
                        </div>
                    </template>
                    <template v-else>
                        <div @click="toSendMsg" class="send-ico w40 h40 f flex_ac flex_jc cur nouse">
                            <span class="el-icon-s-promotion font20"></span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Bus from '@/utils/Bus.js';
import { format } from "date-fns";
import chatUrl from '@/utils/chatUrl.js';
import editorItem from './components/editorItem.vue';
import { mapMutations } from 'vuex'
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { createTopic, getTopicHistory,deleteTopicLogInfo,editTopicLogInfo } from "@/api";
export default {
    name: "chitchat",
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            light:"", // 文本框激活
            sendMsg:"", // 输入框消息
            loadFlag:false, // 加载状态
            topicId: "", // 列表对话id
            msgList: [
                {
                    content: "",
                    crDate: "",
                    role: "",
                    reqLogId:""
                },
            ], // 列表
            startReqLogId:"", // 修改列表项的id
            addUnshiftId:"",// 列表最后一机器人消息
            startEditButtonStatus:false,  //编辑按钮开关 
        }
    },
    components:{
        editorItem
    },
    created() {
        this.msgList = [];
        Bus.$on('getTopicList', this.getTopicList);
        Bus.$on('initList', this.initList)
    },
    computed: {
        scrllBox() {
            return this.$refs.scrllBox;
        }
    },
    methods: {
        ...mapMutations(['setTitle']),
        initList(opt){
            if(opt.msgList){
                this.msgList = [];
            }
            if(opt.focus){
                this.getFoucs()
            }
            if(opt.scroll){
                this.setScroll();
            }
            if(opt.initEdit){
                this.closeEditStatus();
            }
            if(opt.initTopicId){
                this.topicId = "";
            }
        },
        getTopicList(id) {
            this.topicId = id;
            this.getTopicHisty();
        },
        // 查询对话历史
        getTopicHisty() {
            let params = {
                topicId: this.topicId,
            };
            getTopicHistory(params).then((res) => {
                if (res.resultcode == 200) {
                    this.msgList = res.data;
                    this.$nextTick(() => {
                        this.setScroll();
                    });
                } else {
                    this.$message.error(res.reason || "网络错误");
                }
            });
        },
       
        getQuote(str){
            this.closeEditStatus();
            this.sendMsg = str;
            this.getFoucs()
        },
        closeEditStatus(){
            this.startEditButtonStatus = false;
            this.startReqLogId = "";
            this.sendMsg = "";
        },
        // 对话列表删除
        deleMsgItem(id){
            let params = {
                reqLogId: id,
            };
            deleteTopicLogInfo(params).then(res=>{
                if (res.resultcode == 200) {
                    this.getTopicHisty();
                } else {
                    this.$message.error(res.reason || "网络错误");
                }
            })
        },
        getEditValue(id,text) {
            this.startEditButtonStatus = true;
            this.startReqLogId = id;
            this.sendMsg = text;
           
        },
        carriageReturn() {
            let e = window.event || arguments[0];
            if (e.key == "Enter" || e.code == "Enter" || e.keyCode == 13) {
                e.returnValue = false;
                this.toSendMsg();
                return false;
            }
        },
        toSendMsg() {
            this.loadFlag = true;
            let params = {
                context: this.sendMsg,
                topicId: this.topicId,
            };
            if (!this.sendMsg) {
                this.loadFlag = false;
                return;
            }
            console.log(this.topicId,'this.topicId')
            if (!this.topicId) {
                this.createTopicAdd();
                return;
            }

            this.msgList.push({
                content: this.sendMsg,
                crDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                role: "user",
                reqLogId:""
            });

            this.msgList.push({
                content: "",
                crDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
                role: "assistant",
                reqLogId:""
            });

            this.$nextTick(() => {
                this.setScroll();
                this.sendMsg = "";
            });
            this.getMessage(params,params.topicId)
        },
        setTitleName(str,id){
            console.log(str,'this.sendMsg')
            Bus.$emit('setTitleName',{
                topicId:id,
                msg:str
            })
        },
        getMessage(data,id){
            fetchEventSource(chatUrl + "/stream/ai/newChatStream", {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('mtToken')
                },
                body: JSON.stringify(data),
                onmessage:(event)=> {
                    let data = JSON.parse(event.data);
                    if(data.resultcode == "200"){
                        let opt = JSON.parse(event.data);
                        this.addText(opt.data.content, id);
                    }
                    if(data.resultcode == "10026"){
                        this.$message.error(data.reason)
                        this.loadFlag = false;
                        this.getTopicHisty();
                        this.getFoucs();
                        this.msgList.pop();
                        this.setTitle('');
                        Bus.$emit('showRecharge')
                    }
                },
                onerror:(err)=> {
                    console.log(err,'error')
                },
                onclose:(close)=> {
                    this.loadFlag = false;
                    this.getTopicHisty();
                    this.getFoucs();
                    Bus.$emit('sendAfter')
                }
            });
        },
        addText(str, id) {
            if (id == this.topicId) {
                let index = this.msgList.length - 1;
                this.msgList[index].content += str;
                this.setScroll();
            }
        },
        toSaveMsg(){
            if(this.sendMsg){
                this.saveChangeText()
            }
        },
        saveChangeText() {
            this.loadFlag = true;
            let params = {
                content: this.sendMsg,
                reqLogId: this.startReqLogId
            }
            editTopicLogInfo(params).then((res) => {
                this.loadFlag = false;
                this.startEditButtonStatus = false;
                if (res.resultcode == 200) {
                    this.sendMsg = "";
                    this.getTopicHisty();
                    this.$message.success(res.reason || "保存成功");
                } else {
                    this.$message.error(res.reason || "网络错误");
                }
            }).catch(()=>{
                this.loadFlag = false;
            })
        },
        // 自动创建对话
        async createTopicAdd() {
            await this.createdTopMsg(this.sendMsg);
            this.toSendMsg();
        },
        async createdTopMsg(str) {
            let newStr = str.trim().slice(0,200);
            let params = {
                topicName: newStr,
            };
            let res = await createTopic(params);
            if (res.resultcode == 200) {
                this.topicId = res.data.topicId;
                let time = new Date().getTime();
                this.addUnshiftId = time;
                this.msgList = [];
                Bus.$emit('setTitleName',{
                    topicId:this.topicId,
                    msg:str
                })
            } else {
                if(res.resultcode == '10026'){
                    this.loadFlag = false;
                    Bus.$emit('showRecharge')
                    return Promise.reject('错误')
                    
                }else{
                    this.$message.error(res.reason || "网络错误");
                }
            }
        },
        setLight(value) {
            this.light = value;
        },
        getFoucs(){
            this.$nextTick(()=>{
                document.getElementById('mtTextSenTear').focus();
            })
        },
        setScroll() {
            let h = this.scrllBox.scrollHeight;
            this.scrllBox.scrollTop = h;
        },
         // 初始化编辑状态
        closeEditStatus(){
            this.startEditButtonStatus = false;
            this.startReqLogId = "";
            this.sendMsg = "";
        },
    },
    beforeDestroy(){
        Bus.$off('getTopicList')
        Bus.$off('initList')
    }
};
</script>
<style lang="scss"></style>