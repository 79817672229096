<template>
    <div id="app" class="app" :class="isMobile?'phone':'pc'">
        <router-view />
    </div>
</template>
<script>

export default {
    data() {
        return {
            userAgent: ''
        }
    },
    created() {
        this.userAgent = navigator.userAgent;
    },
    computed: {
        isMobile() {
            return ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'].some(item => this.userAgent.includes(item))
        }
    }
}
</script>
